import React from "react"
import styled from "styled-components"
import NavButton from "../components/Nav/NavButton.js"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layout.js"

export default function About() {
  const profilePic = "../../static/profile.png"
  return (
    <Layout>
      <Container>
        <FuturaTitle>About me</FuturaTitle>

        <TwoSide>
          <ImgCont>
            <StaticImage src={profilePic} alt="Nick's Profiel foto" />
          </ImgCont>
          <div>
            <ParaCont>
              <Para>I code and design engaging digital experiences</Para>
              <Para>
                Interested in technology, design, history, philosophy, film,
                animation
              </Para>
              <Para>Also an experienced Online Marketeer & Product Owner.</Para>

              <NavButton />
            </ParaCont>
          </div>
        </TwoSide>
      </Container>
      <Footer />
    </Layout>
  )
}

const Container = styled.section`
  background: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  color: black;
  max-height: auto;
  margin: 0 auto;
  width: 70%;
  // border: 1px dashed black;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const FuturaTitle = styled.h2`
  text-align: center;
  justify-content: center;
  color: black;
  font-family: var(--main-font);
  padding: 25px;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 4em;
  margin-top: 6vh;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    padding: 0px;
    padding-bottom: 2vh;
    margin-top: 50px;
  }
`

const ParaCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // background: red;
`

const Para = styled.div`
  padding: 15px;
  align-self: center;
  font-size: 1.3em;
  width: 100%;
  color: black;
  max-height: auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 1em;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
`

const TwoSide = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 80vw;
  max-height: auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const ImgCont = styled.div`
  width: 300px;
  height: 300px;
  margin: 0 auto;
  margin-top: 40px;
  background: #f2f2f2;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 80vw;
    height: 80vw;
  }
`

const Footer = styled.div`
  height: 10vh;
  background: #f2f2f2;
`
